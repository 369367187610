var render = function render(){
  var _vm$content, _vm$content$fields;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.content ? _c('ContentfulRichTextRenderer', {
    attrs: {
      "document": (_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : (_vm$content$fields = _vm$content.fields) === null || _vm$content$fields === void 0 ? void 0 : _vm$content$fields.helpText
    }
  }) : _vm.error ? _c('div', {
    key: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm.loading ? _c('div', {
    key: "loading"
  }, [_c('Loading', {
    attrs: {
      "absolute": false,
      "size": 50
    }
  })], 1) : _c('div', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    key: "notfound"
  }, [_c('i', [_vm._v("Content not found.")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }