import Action from "@/components/common/action";
import AddressForm from "@/components/common/address";
import Back from "@/components/common/back.vue";
import BarChart from "@/components/charts/bar.vue";
import Block from "@/components/common/block.vue";
import Button from "@/components/common/button.vue";
import Choice from "@/components/common/choice";
import Contentful from "@/components/common/contentful";
import DateTime from "@/components/common/datetime.vue";
import DoughnutChart from "@/components/charts/doughnut.vue";
import Error from "@/components/common/error";
import ErrorPlaceholder from "@/components/common/error-placeholder";
import Errors from "@/components/common/errors";
import Field from "@/components/common/field.vue";
import File from "@/components/common/file.vue";
import Filters from "@/components/filters/filters.vue";
import Graph from "@/components/common/graph";
import Info from "@/components/common/info";
import InfoCard from "@/components/common/infocard";
import LineChart from "@/components/charts/line.vue";
import List from "@/components/list/list.vue";
import Loading from "@/components/common/loading";
import Lookup from "@/components/common/lookup";
import MasterSvg from "@/components/common/master-svg.vue";
import Modal from "@/components/modal/modal";
import ModalContent from "@/components/modal/modalcontent";
import Placeholder from "@/components/common/placeholder";
import Price from "@/components/common/price";
import ProductName from "@/components/common/product-name";
import ReadonlyAccount from "@/components/common/readonlyaccount";
import RessourceError from "@/components/common/ressource-error";
import Select from "@/components/common/select.vue";
import Stat from "@/components/charts/stat";
import Strike from "@/components/common/strike";
import Table from "@/components/table/table.vue";
import Tabs from "@/components/common/tabs";
import TextField from "@/components/fields/text.vue";
import Title from "@/components/common/title.vue";
import Warning from "@/components/common/warning";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

export default {
    install(Vue) {
        Vue.component("Action", Action);
        Vue.component("AddressForm", AddressForm);
        Vue.component("Back", Back);
        Vue.component("BarChart", BarChart);
        Vue.component("Block", Block);
        Vue.component("Button", Button);
        Vue.component("Button", Button);
        Vue.component("Choice", Choice);
        Vue.component("Contentful", Contentful);
        Vue.component("DateTime", DateTime);
        Vue.component("DoughnutChart", DoughnutChart);
        Vue.component("Error", Error);
        Vue.component("ErrorPlaceholder", ErrorPlaceholder);
        Vue.component("Errors", Errors);
        Vue.component("Field", Field);
        Vue.component("File", File);
        Vue.component("Filters", Filters);
        Vue.component("Graph", Graph);
        Vue.component("Info", Info);
        Vue.component("InfoCard", InfoCard);
        Vue.component("LineChart", LineChart);
        Vue.component("List", List);
        Vue.component("Loading", Loading);
        Vue.component("Lookup", Lookup);
        Vue.component("MasterSvg", MasterSvg);
        Vue.component("Modal", Modal);
        Vue.component("ModalContent", ModalContent);
        Vue.component("Placeholder", Placeholder);
        Vue.component("Price", Price);
        Vue.component("ProductName", ProductName);
        Vue.component("ReadonlyAccount", ReadonlyAccount);
        Vue.component("RessourceError", RessourceError);
        Vue.component("Select", Select);
        Vue.component("Stat", Stat);
        Vue.component("Stroke", Strike);
        Vue.component("Table", Table);
        Vue.component("Tabs", Tabs);
        Vue.component("TextField", TextField);
        Vue.component("Title", Title);
        Vue.component("Warning", Warning);
    },
};
