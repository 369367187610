<template>
    <div>
        <ContentfulRichTextRenderer
            v-if="content"
            :document="content?.fields?.helpText"
        />
        <div key="error" v-else-if="error">{{ error }}</div>
        <div key="loading" v-else-if="loading">
            <Loading :absolute="false" :size="50" />
        </div>
        <div key="notfound" v-else v-translate><i>Content not found.</i></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { createClient } from "contentful";
import ContentfulRichTextRenderer from "contentful-rich-text-vue-renderer";
const contentful = require("contentful");

export default {
    components: {
        ContentfulRichTextRenderer,
    },
    props: {
        contentId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            content: null,
            loading: false,
            error: null,
        };
    },
    methods: {
        fetchContent(contentId) {
            let active_locale = this.$store.state.session["active_locale"];

            const client = contentful.createClient({
                space: "pi2rko0ko0vm",
                accessToken: "--8R5Fb_StNt59asxJ849F7yZ6U1rKUN--tJYigUqg8",
            });
            return new Promise((resolve, reject) => {
                client
                    .getEntries({
                        "fields.uniqueId": contentId,
                        content_type: "help",
                        locale: active_locale,
                    })
                    .then((entries) => {
                        if (!entries?.items?.length) {
                            reject(this.$gettext("Did not find content."));
                            return;
                        }
                        resolve(entries.items[0]);
                    });
            });
        },
    },
    created() {
        this.loading = true;
        this.fetchContent(this.contentId)
            .then((content) => {
                this.content = content;
                this.$emit("fetched");
            })
            .catch((error) => {
                this.error = error;
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
</script>
