<template>
    <div class="d-flex flex-column overflow-hidden h-100 overflow-hidden">
        <v-card-title class="mb-0 primary-ultralight-grey">
            <span class="text-h3 pl-1 text-truncate">
                <slot name="title"> </slot>
                <v-icon
                    v-if="helpContentId && uniqueIdExists"
                    class="ml-4"
                    left
                    @click="openHelp"
                    >help_outline</v-icon
                >
            </span>
        </v-card-title>

        <v-card-text
            class="v-card__text flex-grow-1 d-flex flex-1 flex-column h-100 pa-0 overflow-hidden"
        >
            <slot name="rawcontent">
                <div class="overflow-y-scroll px-4 px-sm-6 pt-4 pb-8">
                    <slot name="content"> </slot>
                </div>
            </slot>

            <div
                class="px-2 px-sm-6 py-0 d-sm-flex d-block justify-end primary-ultralight-grey"
            >
                <slot name="bottom"> </slot>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GlobalMixins from "@/mixins/global";
import Help from "@/modals/help.vue";

export default {
    mixins: [GlobalMixins],
    props: {
        helpContentId: {
            type: String,
            required: false,
        },
    },
    computed: Object.assign(mapState("help", ["uniqueIds"]), {
        uniqueIdExists() {
            if (!this.uniqueIds || !this.helpContentId) {
                return false;
            }
            return this.uniqueIds.includes(this.helpContentId);
        },
    }),
    methods: {
        openHelp() {
            this.showModal({
                component: Help,
                params: {
                    contentUniqueId: this.helpContentId,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.v-dialog {
    display: flex;
    .bottom {
        background: red;
    }
}
</style>
