import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import store from "@/stores/store";
import catalogentries from "./submodules/catalogentries";
import providers from "./submodules/providers";

const state = {
    provider: null,
    provider_id: null,
    selected_items: [],
    importing_products: false,
};

const mutations = {
    updateProvider(state, provider) {
        state.provider_id = provider?.id;
        state.provider = provider;
    },
    selectItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            console.log("Item added to catalog", item);
            state.selected_items.push(item);
        }
    },
    deselectItem(state, item) {
        console.log("Item removed from catalog", item);
        state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
    },
    toggleItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            state.selected_items.push(item);
        } else {
            state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
        }
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
    updateImportingProducts(state, importing) {
        state.importing_products = importing;
    },
};

const actions = {

    async changeFilters({ commit, dispatch, state }, filters) {
        let provider_id = filters.provider;

        if (provider_id != state.provider_id) {
            filters.search = null;
        }

        commit("updateProvider", state.providers.providers.find((provider) => provider.id == provider_id));
        commit("providercatalogentries/updateCatalogEntriesFilters", filters);
        if (provider_id) {
            await commit("providercatalogentries/updateCatalogEntriesUrl",
                `/api/providers/${provider_id}/catalogentries/`
            );
            await dispatch("providercatalogentries/fetchCatalogEntries", {});
        }
    },

    async importProducts({ commit, dispatch, state }, params) {
        commit("updateImportingProducts", true);
        try {
            const response = await axios.post(`/api/importproducts/`, {
                products: state.selected_items.map((item) => item.product),
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateImportingProducts", false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        dispatch("providercatalogentries/init", {});
        commit("updateSelectedItems", []);
        dispatch("providers/init", { filters: { limit: 1000 } });
        dispatch("providercatalogentries/init", { filters: { offset: 0, limit: 20, ordering: 'name' } });
        await dispatch("providers/fetchProviders").then((data) => {
            if (params?.provider) {
                commit("updateProvider", params?.provider);
                dispatch("changeFilters", { ...state.providers.providers_filters, provider: params.provider.id });
            } else {
                dispatch("changeFilters", { ...state.providers.providers_filters, provider: data.results[0].id });
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providers: providers(),
        providercatalogentries: catalogentries(),
    },
};
