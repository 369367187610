import store from "@/stores/store";
import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import documents from "./submodules/documents";

const state = {
    shipment: {},
    shipment_saving: false,
    shipment_saving_errors: null,

    options: [],
    options_loading: false,
    options_loading_errors: null,

    stocks: [],
    stocks_loading: false,
    stocks_loading_errors: null,

    customers: [],
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    shipment_type: null,
    delivery_date: null,
    stock: null,

    recipient: null,
    recipient_data: null,

    deliverysite: null,
    deliverysite_data: null,
};

const getters = {
    active_deliverysite(state) {
        return state.deliverysites.find((item) => {
            return item.id == state.shipment.deliverysite;
        });
    },
};

const mutations = {
    updateShipment(state, shipment) {
        state.shipment = JSON.parse(JSON.stringify(shipment || {}));
        state.shipment_saving = false;
        state.shipment_saving_errors = null;

        state.options = [];
        state.options_loading = false;
        state.options_loading_errors = null;

        state.delivery_date = shipment?.delivery_date;
        state.stock = shipment?.stock;
        state.recipient = shipment?.recipient;
        state.recipient_data = state.customers.find(
            (item) => item.id == shipment?.recipient
        );
        state.deliverysite = shipment?.deliverysite;
        state.deliverysite_data = state.deliverysites.find(
            (item) => item.id == shipment?.deliverysite
        );

        if (state.shipment) {
            if (state.shipment.id) {
                if (state.shipment.deliverysite) {
                    if (state.shipment.recipient) {
                        state.shipment_type = "customerdeliverysite";
                    } else {
                        state.shipment_type = "mydeliverysite";
                    }
                } else if (state.shipment.recipient) {
                    state.shipment_type = "customer";
                } else {
                    state.shipment_type = "pickup";
                }
            } else {
                state.shipment_type = "customerdeliverysite";
            }
        } else {
            state.shipment_type = "pickup";
        }
    },
    updateShipmentType(state, shipment_type) {
        state.shipment_type = shipment_type;
        state.shipment.deliverysite = null;
        if (shipment_type == "customer") {
            state.shipment.deliverysite = null;
        } else if (shipment_type == "pickup") {
            state.shipment.deliverysite = null;
            state.shipment.recipient = null;
        } else if (shipment_type == "mydeliverysite") {
            state.shipment.deliverysite = null;
            state.shipment.recipient = null;
        }
    },
    updateShipmentSaving(state, saving) {
        state.shipment_saving = saving;
    },
    updateShipmentSavingErrors(state, errors) {
        state.shipment_saving_errors = errors;
    },
    updateOptions(state, options) {
        state.options = options;
    },
    updateOptionsLoading(state, deleting) {
        state.options_loading = deleting;
    },
    updateOptionsLoadingErrors(state, errors) {
        state.options_loading_errors = errors;
    },
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },
    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
        if (!state.deliverysite_data) {
            state.deliverysite_data = state.deliverysites.find(
                (item) => item.id == state.shipment.deliverysite
            );
        }
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
        if (!state.shipment.stock && stocks.length) {
            let main_stock = state.stocks.find((stock) => stock.main) || state.stocks[0];
            state.shipment.stock = main_stock.id;
            state.stock = main_stock.id;
        }
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },

    toggleOption(state, catalogentry) {
        if (!state.shipment.options) {
            state.shipment.options = [];
        }
        if (
            !state.shipment.options.find((item) => catalogentry.id == item.catalogentry)
        ) {
            state.shipment.options.push({ catalogentry: catalogentry.id });
        } else {
            state.shipment.options = state.shipment.options.filter(
                (item) => catalogentry.id != item.catalogentry
            );
        }
    },

    updateStock(state, stock) {
        state.shipment.stock = stock;
        state.stock = stock;

        if (stock && stock == state.deliverysite_data?.stock) {
            state.shipment.deliverysite = null;
            state.deliverysite = null;
            state.deliverysite_data = null;
        }
    },
    updateDeliveryDate(state, delivery_date) {
        state.shipment.delivery_date = delivery_date;
        state.delivery_date = delivery_date;
    },
    updateRecipient(state, recipient) {
        state.shipment.recipient = recipient;
        state.shipment.deliverysite = null;
        state.recipient = recipient;
        state.deliverysite = null;
        state.recipient_data = state.customers.find(
            (item) => item.id == state.recipient
        );
    },

    updateDeliverySite(state, deliverysite) {
        state.shipment.deliverysite = deliverysite;
        state.deliverysite = deliverysite;
        state.deliverysite_data = state.deliverysites.find(
            (item) => item.id == deliverysite
        );

    },
};

const actions = {
    saveShipment({ commit, dispatch, state }, params) {

        commit("updateShipmentSaving", true);
        commit("updateShipmentSavingErrors", null);

        let url = "/api/shipments/";
        let method = axios.post;
        const shipment = JSON.parse(JSON.stringify(state.shipment || {}));

        switch (state.shipment_type) {
            case "customerdeliverysite":
                break;
            case "mydeliverysite":
                shipment.recipient = null;
                break;
            case "customer":
                shipment.deliverysite = null;
                break;
            case "pickup":
                shipment.recipient = null;
                shipment.deliverysite = null;
                break;
        }

        if (shipment.id) {
            url = `/api/shipments/${shipment.shipment_id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, shipment)
                .then((response) => {
                    commit("updateShipmentSaving", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateShipmentSaving", false);
                    commit("updateShipmentSavingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    fetchOptions({ commit, dispatch, state }, params) {

        if (!state.shipment.stock) {
            commit("updateOptions", []);
            return;
        }
        commit("updateOptionsLoading", true);
        commit("updateOptionsLoadingErrors", null);

        console.log(state.shipment);
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/stocks/${state.shipment.stock}/operatoroptionscatalogentries/`, {
                    params: { trigger: "shipment-option", ordering: "name" },
                })
                .then((response) => {
                    commit("updateOptions", response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateOptionsLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateOptionsLoading", false);
                });
        });
    },

    fetchCustomers({ commit, dispatch, state }, params) {

        commit("updateCustomersLoading", true);
        commit("updateCustomersLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/customers/", {
                    params: { limit: 1000, ordering: "name", profile: "customer" },
                })
                .then((response) => {
                    commit("updateCustomers", response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updateCustomersLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateCustomersLoading", false);
                });
        });
    },

    fetchDeliverySites({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_deliverysite"
            ) == -1
        ) {
            return;
        }


        let url = null;
        if (state.shipment_type == 'customerdeliverysite') {
            if (!state.shipment.recipient) {
                return;
            }
            url = `/api/customers/${state.shipment.recipient}/deliverysites/`;
        } else if (state.shipment_type == 'mydeliverysite') {
            url = `/api/deliverysites/`;
        } else {
            return;
        }
        commit("updateDeliverySitesLoading", true);
        commit("updateDeliverySitesLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: {
                        limit: 1000,
                        ordering: "name",
                    },
                })
                .then((response) => {
                    commit("updateDeliverySites", response.data.results);
                    if (!state.shipment.deliverysite && response.data.results.length) {
                        commit("updateDeliverySite", response.data.results[0].id);
                    }
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updateDeliverySitesLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateDeliverySitesLoading", false);
                });
        });
    },

    fetchStocks({ commit, dispatch, state }, params) {

        commit("updateStocksLoading", true);
        commit("updateStocksLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/stocks/", {
                    params: { limit: 1000 },
                })
                .then((response) => {
                    commit("updateStocks", response.data.results);
                    dispatch("fetchOptions");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updateStocksLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateStocksLoading", false);
                });
        });
    },

    changeStock({ commit, dispatch, state }, stock) {
        commit('updateStock', stock);
        dispatch('fetchOptions');
    },

    init({ commit, dispatch, state }, params) {
        commit("updateShipment", params.shipment);
        dispatch("fetchStocks");
        dispatch("fetchOptions");
        dispatch("fetchCustomers");
        dispatch("fetchDeliverySites");
    },
    changeShipmentType({ commit, dispatch, state }, shipment_type) {

        commit("updateShipmentType", shipment_type);
        dispatch("fetchDeliverySites");
    },

    changeRecipient({ commit, dispatch, state }, customer) {
        commit("updateRecipient", customer);
        dispatch("fetchDeliverySites");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        documents: documents(),
    },
};
