<template>
    <v-alert
        border="left"
        colored-border
        :color="color"
        :elevation="elevation"
        class="text-body-2 mb-4"
        :icon="icon"
    >
        <div class="d-sm-flex d-block pb-1">
            <div class="flex-grow-1 mb-2 pr-0 pr-sm-2">
                <slot />
            </div>
            <slot name="button" />
        </div>
    </v-alert>
</template>

<script>
export default {
    props: {
        elevation: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            required: false,
            default: "info",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    methods: {},
};
</script>
