<template>
    <span :class="{ link: !error }" @click="openObject">
        <slot />
    </span>
</template>

<script>
import axios from "axios";
import utils from "@/stores/utils";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        object: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async openObject() {
            if (this.error) {
                return;
            }
            try {
                let params = {};
                params[this.key] = this.id;

                const response = await axios.get(`/api/${this.object}s/`, {
                    params: this.params,
                });
                if (response.data.results) {
                    let key = `${this.object}_id`;
                    params = {};
                    params[key] = response.data.results[0].id;

                    this.routerPush({
                        name: this.object,
                        params,
                    });
                } else {
                    this.addAlert({ text: this.$gettext("Invalid link"), type: "error" });
                    this.error = true;
                }
            } catch (xhr_error) {
                this.$swal({
                    title: this.$gettext("Error opening link"),
                    html: utils.resultToSwalContent(this, xhr_error),
                    type: "error",
                });
            }
        },
    },
    data() {
        return {
            error: false,
        };
    },
};
</script>
