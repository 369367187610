import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import orderitems from "./submodules/orderitems";
import receiptitems from "./submodules/receiptitems";

const state = {
    product: null,
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    producttypes: [],
    producttypes_loading: false,
    producttypes_loading_errors: null,

    productpictures: [],
    productpictures_count: 0,
    productpictures_loading: false,
    productpictures_loading_errors: null,

    productpicture_saving: false,
    productpicture_saving_errors: null,

    productproviders: [],
    productproviders_count: 0,
    productproviders_filters: { limit: 20 },
    productproviders_loading: false,
    productproviders_loading_errors: null,
    productproviders_saving: false,
    productproviders_saving_errors: null,

    product_stats: {},
    product_stats_loading: false,
    product_stats_loading_errors: null,

    section: null,
    creating_sale_product: false,
};


const mutations = {

    updateProduct(state, product) {
        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.product = product;
        state.creating_sale_product = false;

        state.productpictures = [];
        state.productpictures_count = 0;
    },

    updateSection(state, section) {
        state.section = section;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductDeleting(state, deleting) {
        state.product_deleting = deleting;
    },

    updateProductPictures(state, productpictures) {
        state.productpictures = productpictures;
    },
    updateProductPicturesCount(state, count) {
        state.productpictures_count = count;
    },
    updateProductPicturesLoading(state, loading) {
        state.productpictures_loading = loading;
    },
    updateProductPicturesLoadingErrors(state, errors) {
        state.productpictures_loading_errors = errors;
    },

    updateProductPictureSavingErrors(state, errors) {
        state.productpicture_saving_errors = errors;
    },
    updateProductPictureSaving(state, saving) {
        state.productpicture_saving = saving;
    },


    updateProductStats(state, stats) {
        state.product_stats = stats;
    },
    updateProductStatsLoading(state, loading) {
        state.product_stats_loading = loading;
    },
    updateProductStatsLoadingErrors(state, errors) {
        state.product_stats_loading_errors = errors;
    },

    updateCreatingSaleProduct(state, creating) {
        state.creating_sale_product = creating;
    }

};

const actions = {

    async fetchProductStats({ commit, dispatch, state }, params) {
        commit('updateProductStatsLoading', true);
        try {
            const response = await axios.get(`/api/providerproducts/${state.product.id}/stats/`);
            commit('updateProductStats', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductStatsLoading', false);
        }
    },

    async fetchProduct({ commit, dispatch, state }, params) {
        commit('updateProductLoading', true);
        let url = null;
        if (params && params.product_id) {
            url = `/api/providerproducts/${params.product_id}/`;
        } else if (state.product) {
            url = `/api/providerproducts/${state.product.id}/`;
        } else {
            throw "no product to fetch";
        }
        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async deleteProduct({ commit, dispatch, state }, params) {
        commit('updateProductDeleting', true);

        const url = `/api/providerproducts/${params.instance.id}/`;
        try {
            const response = await axios.delete(url);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateProductDeleting', false);
        }
    },

    async fetchProductPictures({ commit, dispatch, state }, params) {
        commit('updateProductPicturesLoading', true);
        commit('updateProductPicturesLoadingErrors', null);

        try {
            const response = await axios.get('/api/productpictures/', { params: { limit: 100, product: state.product.id } });
            commit('updateProductPictures', response.data.results);
            commit('updateProductPicturesCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPicturesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPicturesLoading', false);
        }
    },

    saveProductPicture({ commit, dispatch, state }, params) {
        if (!params || !params.instance) {
            return;
        }

        let headers = {};
        let data = params.instance;
        let url = '/api/productpictures/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/productpictures/${params.instance.id}/`;
            method = params.patch ? axios.patch : axios.put;
        } else {
            headers = { 'Content-Type': 'multipart/form-data' };
            data = new FormData();
            for (let key in params.instance) {
                if (params.instance[key]) {
                    data.append(key, params.instance[key]);
                }
            }
        }

        commit('updateProductPictureSavingErrors', null);
        commit('updateProductPictureSaving', true);

        return new Promise((resolve, reject) => {
            method(url, data, { headers })
                .then((response) => {
                    resolve({ productpicture: response.data });
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPictureSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    commit('updateProductPictureSaving', false);
                })
        });
    },


    deleteProductPicture({ commit, dispatch, state }, params) {
        const url = `/api/productpictures/${params.instance.id}/`;

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },


    async init({ commit, dispatch, state }, params) {
        dispatch('providerorderitems/init', {
            filters: { product: params.product.id, }, type: 'providerorder',
        });
        dispatch('receiptitems/init', {
            filters: { product: params.product.id },
        });
        commit('updateProduct', params.product);
        dispatch('fetchProductStats');
    },

    async createSaleProduct({ commit, dispatch, state }, params) {
        commit("updateCreatingSaleProduct", params.catalogentries);
        try {
            const response = await axios.post(`/api/importproducts/`, {
                products: [state.product.id],
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            dispatch("fetchProduct");
            commit("updateCreatingSaleProduct", params.products);
        }
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providerorderitems: orderitems(),
        receiptitems: receiptitems(),
    },
};

