var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-grow-1 primary-lighter3 py-4 px-4",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('b', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._t("title")], 2), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }