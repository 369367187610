<template>
    <div class="flex-grow-1 primary-lighter3 py-4 px-4" style="flex: 1">
        <b class="text-overline font-weight-bold"> <slot name="title"> </slot></b>
        <slot></slot>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    name: "blocks",
};
</script>
