import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    stock: null,
    order: null,
    comments: null,

    shipment: null,
    shipment_saving: false,
    shipment_saving_errors: null,
    shipment_creating: false,
    shipment_creating_errors: null,

    solutions: [],
    solutions_loaded: false,
    solutions_loading: null,
    solutions_loading_errors: null,

};

const getters = {
}
const mutations = {
    updateOrder(state, order) {
        state.order = order;
        state.solutions_loaded = false;
        state.solutions = [];
        state.comments = order.comments;
    },
    updateShipmentSaving(state, saving) {
        state.shipment_saving = saving;
    },
    updateShipmentSavingErrors(state, errors) {
        state.shipment_saving_errors = errors;
    },
    updateComments(state, comments) {
        state.comments = comments;
    },
    updateItemCount(state, { order_id, product, count }) {
        state.solutions.orders.find((order) => order.id == order_id).shipmentitems.find((item) => item.product == product).count = count;
    },

    updateSolutions(state, solutions) {

        if (solutions === null) {
            state.solutions = [];
            state.solutions_loaded = false;
            state.solutions_loading = false;
            state.solutions_loading_errors = null;
            return;
        }

        solutions.orders.map((solution) => {
            solution.shipmentitems = [];
            solution.shipmentitems = solution.items.map(element => {
                console.log(element.stock_tracking, element.to_schedule);
                let max = Math.min(element.to_schedule, element.stock_count);
                if (!element.stock_tracking) {
                    max = element.to_schedule;
                }
                return {
                    count: max,
                    enabled: !element.stock_tracking || element.stock_count && element.to_schedule > 0,
                    stock_count: element.stock_count,
                    stock_tracking: element.stock_tracking,
                    to_schedule: element.to_schedule,
                    max: max,
                    product: element.product,
                    product_data: element.product_data,
                    orderitem_data: element.orderitem_data,
                }
            });
            // Find solution on existing state.solutions and replace it inplace, else push it on state.solutions
            let solution_index = state.solutions.findIndex((item) => item.order_data.id == solution.order_data.id);
            if (solution_index != -1) {
                state.solutions[solution_index] = solution;
            } else {
                state.solutions.push(solution);
            }
        });
        // Ordering solutions to have first solutions that have items with count > 0
        state.solutions = state.solutions.sort((a, b) => {
            let a_to_schedule = a.shipmentitems.reduce((acc, item) => acc + item.to_schedule, 0);
            let b_to_schedule = b.shipmentitems.reduce((acc, item) => acc + item.to_schedule, 0);
            if (a_to_schedule == b_to_schedule) {
                if (a.order_data.order_id < b.order_data.order_id) {
                    return -1;
                }
                return 0;
            }
            return b_to_schedule - a_to_schedule;
        });
        state.solutions_loaded = true;
        state.solutions_loading = false;
        state.solutions_loading_errors = null;
    },
    updateSolutionsLoading(state, solutions_loading) {
        state.solutions_loading = solutions_loading;
        state.solutions_loading_errors = null;
    },
    updateSolutionsLoadingErrors(state, solutions_loading_errors) {
        state.solutions_loading = false;
        state.solutions_loading_errors = solutions_loading_errors;
    },

    updateShipmentCreating(state, shipment_creating) {
        state.shipment_creating = shipment_creating;
        state.shipment_creating_errors = null;
    },

    updateShipmentCreatingErrors(state, shipment_creating_errors) {
        state.shipment_creating = false;
        state.shipment_creating_errors = shipment_creating_errors;
    },
    updateShipment(state, shipment) {
        state.shipment_creating = false;
        state.shipment = shipment;
    }
};

const actions = {

    createShipment({ commit, dispatch, state }, { order }) {
        commit('updateShipmentCreating', true);
        let items = order.shipmentitems.filter((item) => item.enabled);
        let payload = {
            order: order.order_data.id,
            stock: order.stock,
            delivery_date: order.delivery_date,
            items: items.map((item) => {
                return {
                    product: item.product,
                    count: item.count,
                }
            })
        };
        return new Promise((resolve, reject) => {
            axios.post(`/api/customerorders/${order.order_data.id}/createshipment/`, payload)
                .then((response) => {
                    commit('updateShipment', response.data);
                    dispatch('order/update', null, { root: true });
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateShipmentCreatingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                })
        });
    },

    setOrder({ commit, dispatch, state }, params) {
        commit('updateOrder', params);
        commit('updateSolutions', null);
        return dispatch('fetchSolutions');
    },

    setStock({ commit, dispatch, state }, params) {
        commit('updateStock', params);
        return dispatch('fetchSolutions');
    },

    fetchSolutions({ commit, dispatch, state }, params) {
        commit('updateSolutionsLoading', true);
        let order = params?.order || state.order.id;

        return new Promise((resolve, reject) => {
            axios.get(`/api/customerorders/${order}/solution/`, { params: { stock: params?.stock } })
                .then((response) => {
                    commit('updateSolutions', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateSolutionsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
